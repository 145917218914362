// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import DateFnsProvider from './DateFnsProvider';

// ----------------------------------------------------------------------


export default function App() {

    return(
        <ThemeProvider>
            <ThemeColorPresets>
                <DateFnsProvider>
                    <RtlLayout>
                        <MotionLazyContainer>
                            <GlobalStyles/>
                            <ProgressBarStyle/>
                            <Settings/>
                            <ScrollToTop/>
                            <Router/>
                        </MotionLazyContainer>
                    </RtlLayout>
                </DateFnsProvider>
            </ThemeColorPresets>
        </ThemeProvider>
    );
}
