import { useExpiringCommissionBoostsQuery } from '../../generated/graphql';
import { RegistrationsTable, RWATableCategory } from "./RegistrationsWithoutAnswersTable";

export function ExpiringCommissionBoostsTable(props: {relevantSupervisors: string[]}) {

  const { data } = useExpiringCommissionBoostsQuery({ variables: { earliest_end_date: '2022-11-01' } });
  const allCV = data?.support_expiring_boosts ?? [];

  let relevantCV
  if(props.relevantSupervisors.length === 0){
    relevantCV = allCV
  } else {
    relevantCV = allCV?.filter(registration => props.relevantSupervisors.indexOf(registration.vendor?.supervisor ?? '') > -1) ?? []
  }

  const cv = relevantCV.filter(singleCV =>
      (!singleCV.vendor?.metadata?.includes('noProf')) && singleCV.vendor?.country_code === 'DE'
  )

  const headers = [
    RWATableCategory.VENDOR_ID,
    RWATableCategory.VENDOR_NAME,
    RWATableCategory.SUPERVISOR,
    RWATableCategory.END_DATE,
    RWATableCategory.FACTOR,
    RWATableCategory.COMMENT,
  ];

  const nonSortableCategories = [
    RWATableCategory.COMMENT
  ];

  return (
    <RegistrationsTable headers={headers} nonSortableHeaders={nonSortableCategories} registrations={cv} setDiscardTask={null} defaultSorting={RWATableCategory.END_DATE}/>
  );
}