import { styled } from '@mui/material/styles';
import {
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import { RegistrationRequestFragment } from '../../generated/graphql';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { DenialReason, RegistrationStatus, VendorSize } from '../../pages/PageProfilerRegistration';
import React, { PropsWithChildren } from 'react';
import { Alert } from '@mui/lab';

export const CustomGridHeading = styled(Typography)`
  grid-column: span 2;
`;

export const CustomGrid = styled('div')`
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 12px;
  row-gap: 8px;
  align-content: start;
`;

export const GridItem = styled('div')`
  display: contents;
  white-space: nowrap;
`;

function AcceptDeclineButtons(props: {
  isInfoComplete: boolean;
  onAccept: (e: React.MouseEvent) => void;
  onDeny: (e: React.MouseEvent) => void;
  onEdit: (e: React.MouseEvent) => void;
}) {
  return (
    <Box display="flex" gap={2}>
      {props.isInfoComplete ? (
        <Button color="success" variant="outlined" onClick={props.onAccept}>
          <CheckIcon />
          Zulassen
        </Button>
      ) : (
        <Button color={'info'} variant="outlined" onClick={props.onEdit}>
          <EditIcon />
          Daten vervollständigen
        </Button>
      )}
      <Button color="error" variant="outlined" onClick={props.onDeny}>
        <ClearIcon />
        Ablehnen
      </Button>
      {props.isInfoComplete ? (
        <Button color="info" variant="outlined" onClick={props.onEdit}>
          <EditIcon />
          Bearbeiten
        </Button>
      ) : null}
    </Box>
  );
}

export function formatDate(date: string, withClockSuffix?: boolean): string {
  let formattedDate = new Date(date).toLocaleString('de-DE', {
    dateStyle: 'medium',
    timeStyle: 'medium',
  });

  if (withClockSuffix) {
    formattedDate += ' Uhr';
  }

  return formattedDate;
}

export function DataItem({ label, children }: PropsWithChildren<{ label: string }>) {
  return (
    <GridItem>
      <Typography color="text.secondary" fontWeight="bold">
        {label}:
      </Typography>
      <div>{children == null || children === '' ? '—' : children}</div>
    </GridItem>
  );
}

export function RegistrationStatusWidget({ status }: { status: RegistrationStatus }) {
  function Status() {
    switch (status) {
      case RegistrationStatus.sentConfirmationEmail:
        return <Typography>Registrierung erfasst</Typography>;
      case RegistrationStatus.accessGranted:
      case RegistrationStatus.accessGrantedSent:
        return (
          <>
            <CheckIcon color="success" />
            <Typography color="success.main">Akzeptiert</Typography>
          </>
        );
      case RegistrationStatus.accessDenied:
      case RegistrationStatus.accessDeniedSent:
        return (
          <Badge>
            <ClearIcon color="error" />
            <Typography color="error.main">Abgelehnt</Typography>
          </Badge>
        );
    }
  }

  return (
    <Box display="flex" alignItems={'center'}>
      <Status />
    </Box>
  );
}

export function isPreorder(registration: RegistrationRequestFragment) {
  return parsePreorder(registration) !== 0;
}

export function parsePreorder(registration: RegistrationRequestFragment): number {
  let parsed = Number(registration.vendor?.preorder);
  if (Number.isNaN(parsed) || !Number.isFinite(parsed)) {
    parsed = 0;
  }
  return parsed;
}

export function RegistrationCard({
  onEdit,
  onAccept,
  onDeny,
  registration,
  showDuplicateDialog,
  showActions = true,
}: {
  registration: RegistrationRequestFragment;
  onEdit: (r: RegistrationRequestFragment) => void;
  onAccept: (r: RegistrationRequestFragment) => void;
  onDeny: (r: RegistrationRequestFragment) => void;
  showDuplicateDialog: (r: RegistrationRequestFragment) => void;
  showActions?: boolean;
}) {
  // noinspection RegExpUnnecessaryNonCapturingGroup,RegExpRedundantEscape
  const EMAIL_REGEX =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  function isInfoComplete(registration: RegistrationRequestFragment): boolean {
    function notNullOrEmpty(str: string | null | undefined) {
      return str != null && str !== '';
    }

    return (
      notNullOrEmpty(registration.email_address) &&
      EMAIL_REGEX.test(registration.email_address) &&
      notNullOrEmpty(registration.vendor_id)
    );
  }

  function formatEmailStatus(status: RegistrationStatus) {
    switch (status) {
      case RegistrationStatus.sentConfirmationEmail:
        return '-';
      case RegistrationStatus.accessGranted:
      case RegistrationStatus.accessDenied:
        return 'Noch nicht versendet';

      case RegistrationStatus.accessGrantedSent:
      case RegistrationStatus.accessDeniedSent:
        return 'E-Mail versendet';
    }
  }

  function formatReason(reason: DenialReason) {
    switch (reason) {
      case DenialReason.notAPartner:
        return 'Kein SQlab Partner';
      case DenialReason.duplicateRegistration:
        return 'Doppelte Anmeldung';
      case DenialReason.noPreorder:
        return 'Keine Vororder';
      case DenialReason.unsupportedCountry:
        return 'Land nicht unterstützt';
    }
  }

  const duplicateCount = registration.duplicate_count.aggregate?.count ?? 1;

  function formatVendorSize(vendorSize: VendorSize) {
    switch (vendorSize) {
      case VendorSize.fewerThanFive:
        return '< 5';
      case VendorSize.betweenFiveAndFifteen:
        return '5 - 15';
      case VendorSize.moreThanFifteen:
        return '> 15';
    }
  }

  function formatPreorder(registration: RegistrationRequestFragment) {
    let parsed = parsePreorder(registration);
    return parsed.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
  }

  return (
    <Card elevation={2} sx={{ mb: 2, borderColor: 'success.main' }}>
      <CardContent>
        <Stack direction="row" gap={4} sx={{ overflow: 'auto' }}>
          <CustomGrid>
            <CustomGridHeading variant="h5">Kontakt</CustomGridHeading>
            <DataItem label="Datum">{formatDate(registration.created_at)}</DataItem>
            <DataItem label="Nachname, Vorname">
              {registration.surname + ', ' + registration.first_name}
            </DataItem>
            <DataItem label="E-Mail">{registration.email_address}</DataItem>
            <DataItem label={'Zusätzliche E-Mails'}> </DataItem>
            {registration.additional_email_addresses?.split(',').map((mail) => (
              <Typography sx={{ gridColumn: 'span 2' }} key={mail}>
                {mail}
              </Typography>
            ))}
          </CustomGrid>
          <CustomGrid>
            <CustomGridHeading variant="h5">Kunde</CustomGridHeading>
            <DataItem label="Firmenname">{registration.vendor_name}</DataItem>
            <DataItem label="Kundennummer">{registration.vendor_id}</DataItem>
            <DataItem label="Mitarbeiter">
              {formatVendorSize(registration.vendor_size as VendorSize)}
            </DataItem>
            <DataItem label="iPad verfügbar">
              {registration.ipad_available ? 'Ja' : 'Nein'}
            </DataItem>
            <DataItem label="Kundenbetreuer">{registration.vendor?.supervisor}</DataItem>
            <DataItem label="Land">{registration.vendor?.country_code}</DataItem>
            <DataItem label="Sprache">{registration.language}</DataItem>
            <DataItem label="Vororder">{formatPreorder(registration)}</DataItem>
          </CustomGrid>
          <CustomGrid>
            <CustomGridHeading variant="h5">Status</CustomGridHeading>
            <DataItem label="Status">
              <RegistrationStatusWidget status={registration.status as RegistrationStatus} />
            </DataItem>
            <DataItem label="E-Mail-Status">
              {formatEmailStatus(registration.status as RegistrationStatus)}
            </DataItem>
            <DataItem label="Zuletzt aktualisiert">{formatDate(registration.updated_at)}</DataItem>
            <DataItem label="Kommentar">{registration.comment}</DataItem>
            {registration.deny_reason != null ? (
              <DataItem label="Ablehnungsgrund">
                {formatReason(registration.deny_reason as DenialReason)}
              </DataItem>
            ) : null}
            {registration.registration_email_sent_at != null ? (
              <DataItem label="Mail verschickt">
                {registration.registration_email_sent_at != null
                  ? formatDate(registration.registration_email_sent_at)
                  : null}
              </DataItem>
            ) : null}
            <DataItem label={'ID'}>{registration.id}</DataItem>
          </CustomGrid>
        </Stack>
        {showActions ? (
          <Box mt={2} gap={1} display="flex" flexDirection="column">
            {duplicateCount > 1 ? (
              <Alert
                severity="warning"
                action={
                  <Button
                    color="info"
                    variant="text"
                    size={'small'}
                    onClick={() => showDuplicateDialog(registration)}
                  >
                    Details
                  </Button>
                }
              >
                Mögliches Duplikat: {duplicateCount - 1} Anmeldung
                {duplicateCount > 2 ? 'en' : null} mit der selben Kundennummer
              </Alert>
            ) : null}
            {isInfoComplete(registration) ? null : (
              <Alert severity="error">
                Unvollständige informationen: E-Mail-Adresse oder Kundennummer fehlen/sind
                unvollständig
              </Alert>
            )}
            {isPreorder(registration) ? <Alert severity="warning">Keine Vororder</Alert> : null}
          </Box>
        ) : null}
      </CardContent>
      {showActions ? (
        <CardActions>
          {registration.status === RegistrationStatus.sentConfirmationEmail ? (
            <AcceptDeclineButtons
              isInfoComplete={isInfoComplete(registration)}
              onAccept={() => onAccept(registration)}
              onDeny={() => onDeny(registration)}
              onEdit={() => onEdit(registration)}
            />
          ) : (
            <Button color="info" variant="outlined" onClick={() => onEdit(registration)}>
              <EditIcon />
              Bearbeiten
            </Button>
          )}
        </CardActions>
      ) : null}
    </Card>
  );
}
