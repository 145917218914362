import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps, Stack, Typography } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
    disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
    const logo = (
        <Box sx={{ width: 40, height: 40, ...sx }}>
            <Stack direction="row">
                <img src="/logo/SQLogo_Snake_orange.png" alt={"SQlab Logo"}/>
                <Typography variant="h3">Profiler</Typography>
            </Stack>
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink style={{ textDecoration: 'none', color: "inherit" }} to="/">{logo}</RouterLink>;
}
