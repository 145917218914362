import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
// components
import LoadingScreen from './components/LoadingScreen';
import PageProfilerRegistration from './pages/PageProfilerRegistration';
import PageFollowUpEmails from './pages/PageFollowUpEmails';
import PageTasks from './pages/PageTasks';

// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/metabase" replace />, index: true },
        {
          path: '/dashboard/answers/:id',
          element: <PageCustomerAnswerDetail />,
        },
        { path: '/dashboard/answers', element: <PageCustomerAnswerList /> },
        { path: '/dashboard/cv', element: <PageCVDebug /> },
        { path: '/dashboard/support', element: <PageSupport /> },
        { path: '/dashboard/boost', element: <PageBoost /> },
        { path: '/dashboard/metabase', element: <PageMetabase /> },
        { path: '/dashboard/registration', element: <PageProfilerRegistration /> },
        { path: '/dashboard/follow-up-emails', element: <PageFollowUpEmails /> },
        { path: '/dashboard/tasks', element: <PageTasks /> },
        { path: '/dashboard/follow-up-dashboard', element: <FollowUpDashboard /> },
        { path: '/dashboard/vendor-support', element: <VendorSupport /> },
        { path: '/dashboard/points-boost', element: <PagePointsBoost /> },
        { path: '/dashboard/product-matrix', element: <PageProductMatrix /> },
        // {
        //   path: '/dashboard/user',
        //   children: [
        //     { element: <Navigate to="/dashboard/user/four" replace />, index: true },
        //
        //     { path: '/dashboard/user/six', element: <PageSix /> },
        //   ],
        // },
      ],
    },
    {},
    {
      path: '/retail',
      element: <LogoOnlyLayout />,
      children: [{ path: '/retail/answer/:id', element: <PageCustomerAnswerDetail /> }],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const PageCustomerAnswerList = Loadable(lazy(() => import('./pages/retail/CustomerAnswerList')));
const PageCustomerAnswerDetail = Loadable(
  lazy(() => import('./pages/retail/DetailedCustomerAnswer'))
);

const PageCVDebug = Loadable(lazy(() => import('./pages/PageCVDebug')));
const PageSupport = Loadable(lazy(() => import('./pages/PageSupport')));
const PageMetabase = Loadable(lazy(() => import('./pages/PageMetabase')));
const PageBoost = Loadable(lazy(() => import('./pages/PageCommissionBoost')));
const PagePointsBoost = Loadable(lazy(() => import('./pages/PagePointsBoost')));
const NotFound = Loadable(lazy(() => import('./pages/Page404')));
const FollowUpDashboard = Loadable(lazy(() => import('./pages/FollowUpDashboard')));
const VendorSupport = Loadable(lazy(() => import('./pages/VendorSupport')));
const PageProductMatrix = Loadable(lazy(() => import('./pages/PageProductMatrix')));
