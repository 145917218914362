import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import RefreshIcon from '@mui/icons-material/Refresh';
import useSettings from '../hooks/useSettings';
import Page from '../components/Page';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import {
  RegistrationRequestFragment,
  useDenyRegistrationMutation,
  useGetRegistrationRequestsQuery,
  useUpdateRegistrationMutation,
  useUpdateRegistrationStatusMutation,
} from '../generated/graphql';
import { isPreorder, RegistrationCard } from '../components/registration/RegistrationCard';
import { ChangeEvent, ReactChild, useEffect, useState } from 'react';
import { RegistrationEditDialog } from '../components/registration/RegistrationEditDialog';
import { DuplicateDialog } from '../components/registration/DuplicateDialog';
import { styled } from '@mui/material/styles';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

export enum RegistrationStatus {
  sentConfirmationEmail = 'SENT_CONFIRMATION_EMAIL',
  accessGranted = 'ACCESS_GRANTED',
  accessDenied = 'ACCESS_DENIED',
  accessGrantedSent = 'SENT_ACCESS_GRANTED',
  accessDeniedSent = 'SENT_ACCESS_DENIED',
}

export enum VendorSize {
  fewerThanFive = 'FEWER_THAN_FIVE',
  betweenFiveAndFifteen = 'BETWEEN_FIVE_AND_FIFTEEN',
  moreThanFifteen = 'MORE_THAN_FIFTEEN',
}

export enum DenialReason {
  notAPartner = 'NOT_A_SQLAB_PARTNER',
  duplicateRegistration = 'DUPLICATE_REGISTRATION',
  noPreorder = 'NO_PREORDER',
  unsupportedCountry = 'UNSUPPORTED_COUNTRY',
}

const Grid = styled('div')`
  display: grid;
  grid-template-columns: auto auto auto;
`;

function GridItem({
  title,
  value1,
  value2,
}: {
  title: string;
  value1: ReactChild;
  value2: ReactChild;
}) {
  const Left = styled('div')`
    align-self: start;
  `;

  const Right = styled('div')`
    align-self: end;
  `;

  return (
    <>
      <Typography fontWeight="bold">{title}</Typography>
      <Right>{value1}</Right>
      <Left>{value2}</Left>
    </>
  );
}

export default function PageProfilerRegistration() {
  const { themeStretch } = useSettings();

  // region Graphql Queries
  const [statusFilter, setStatusFilter] = useState<RegistrationStatus[]>([
    RegistrationStatus.sentConfirmationEmail,
    RegistrationStatus.accessGranted,
    RegistrationStatus.accessDenied,
  ]);
  const { data, loading, refetch } = useGetRegistrationRequestsQuery({
    variables: { status: statusFilter },
  });
  const [updateRegistration] = useUpdateRegistrationMutation();
  const [setRegistrationState] = useUpdateRegistrationStatusMutation();

  const [denyRegistration] = useDenyRegistrationMutation();

  // endregion

  // region Edit Modal
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [registrationUnderEdit, setRegistrationUnderEdit] =
    useState<RegistrationRequestFragment | null>(null);

  function editRegistration(registration: RegistrationRequestFragment) {
    setRegistrationUnderEdit(registration);
    setEditModalOpen(true);
  }

  function closeEditModal() {
    setEditModalOpen(false);
  }

  async function saveRegistration() {
    const r = registrationUnderEdit;
    if (r == null) {
      console.error('no registration to update!');
      return;
    }
    await updateRegistration({
      variables: {
        id: r.id,
        registration: {
          first_name: r.first_name,
          surname: r.surname,
          email_address: r.email_address,
          vendor_name: r.vendor_name,
          vendor_id: r.vendor_id,
          vendor_size: r.vendor_size,
          ipad_available: r.ipad_available,
          comment: r.comment,
          additional_email_addresses: r.additional_email_addresses,
          language: r.language,
        },
      },
    });
    closeEditModal();
    await refetch();
  }

  // endregion

  // region Duplicates Modal
  const [duplicateModalOpen, setDuplicateModalOpen] = useState<boolean>(false);
  const [duplicateIds, setDuplicateIds] = useState<number[]>([]);

  function showDuplicateDetails(registration: RegistrationRequestFragment) {
    const ids = registration.vendor_registrations
      .map((r) => r.id)
      .filter((id) => id !== registration.id);
    setDuplicateIds(ids);
    setDuplicateModalOpen(true);
  }

  // endregion duplicates modal

  // region Deny Modal

  const [denyModalOpen, setDenyModalOpen] = useState<boolean>(false);
  const [denialReason, setDenialReason] = useState<{
    reason: DenialReason;
    comment: string;
  }>({
    reason: DenialReason.duplicateRegistration,
    comment: '',
  });
  const [registrationToDeny, setRegistrationToDeny] = useState<RegistrationRequestFragment | null>(
    null
  );

  function showDenyRegistrationModal(registration: RegistrationRequestFragment) {
    setDenialReason({ reason: DenialReason.duplicateRegistration, comment: '' });
    setDenyModalOpen(true);
    setRegistrationToDeny(registration);
  }

  function closeDenyModal() {
    setDenyModalOpen(false);
  }

  async function onDenyConfirmed() {
    if (registrationToDeny == null) {
      console.error('no registration to deny set');
      return;
    }
    setDenyModalOpen(false);
    await denyRegistration({
      variables: {
        id: registrationToDeny.id,
        reason: denialReason.reason,
        comment: denialReason.comment === '' ? null : denialReason.comment,
      },
    });
    await refetch({ status: statusFilter });
  }

  function onReasonChange(e: SelectChangeEvent) {
    setDenialReason({ reason: e.target.value as DenialReason, comment: denialReason.comment });
  }

  function onCommentChange(event: ChangeEvent<HTMLInputElement>) {
    setDenialReason({ reason: denialReason.reason, comment: event.target.value });
  }

  // endregion

  // region Mismatch Modal
  const [mismatchModalOpen, setMismatchModalOpen] = useState(false);
  const [mismatchedRegistration, setMismatchedRegistration] =
    useState<RegistrationRequestFragment | null>(null);

  // endregion

  async function triggerEmailPipeline() {
    try {
      await fetch(
        'https://gitlab.com/api/v4/projects/39235935/trigger/pipeline?' +
          new URLSearchParams({
            token: 'glptt-dca55009189fb0cdd52bec647e262e17485ad2e5',
            ref: 'master',
            'variables[SCHEDULE_TYPE]': 'REGISTRATION_EMAILS',
          }),
        {
          method: 'POST',
        }
      );
      alert('E-Mail-Versand gestartet');
    } catch (e) {
      alert('E-Mail-Versand konnte nicht gestartet werden');
    }
  }

  async function acceptRegistration(registration: RegistrationRequestFragment) {
    const nameMatches =
      registration.vendor_name?.toLowerCase() === registration.vendor?.shop_name?.toLowerCase();
    const vendorMail = registration.vendor?.email_address?.toLowerCase() ?? '';
    const registrationMail = registration.email_address.toLowerCase();

    const emailMatches =
      vendorMail === registrationMail ||
      vendorMail.split('@')[1] === registrationMail.split('@')[1];

    if (nameMatches && emailMatches) {
      await setRegistrationToAccepted(registration);
    } else {
      setMismatchedRegistration(registration);
      setMismatchModalOpen(true);
    }
  }

  async function setRegistrationToAccepted(registration: RegistrationRequestFragment) {
    setMismatchModalOpen(false);
    await setRegistrationState({
      variables: {
        id: registration.id,
        status: RegistrationStatus.accessGranted,
      },
    });
    await refetch({ status: statusFilter });
  }

  function handleShowOnlyNewChanged(e: ChangeEvent<HTMLInputElement>) {
    const showOnlyNew = e.target.checked;
    if (showOnlyNew) {
      setStatusFilter([
        RegistrationStatus.sentConfirmationEmail,
        RegistrationStatus.accessGranted,
        RegistrationStatus.accessDenied,
      ]);
    } else {
      setStatusFilter([
        RegistrationStatus.sentConfirmationEmail,
        RegistrationStatus.accessGranted,
        RegistrationStatus.accessDenied,
        RegistrationStatus.accessGrantedSent,
        RegistrationStatus.accessDeniedSent,
      ]);
    }
  }

  const VALUE_NOT_PRESENT = '';
  const FILTER_NOT_SET = '';

  const [countries, setCountries] = useState(getCountryCodes);
  const [supervisors, setSupervisors] = useState(getSupervisorNames);
  const [isFilterActive, setIsFilterActive] = useState(true);

  const [vendorIdFilter, setVendorIdFilter] = useState(FILTER_NOT_SET);
  const [supervisorFilter, setSupervisorFilter] = useState(FILTER_NOT_SET);
  const [vendorNameFilter, setVendorNameFilter] = useState(FILTER_NOT_SET);
  const [countryFilter, setCountryFilter] = useState(FILTER_NOT_SET);
  const [preorderFilter, setPreorderFilter] = useState(FILTER_NOT_SET);

  const resetFilters = () => {
    setVendorIdFilter(FILTER_NOT_SET);
    setSupervisorFilter(FILTER_NOT_SET);
    setVendorNameFilter(FILTER_NOT_SET);
    setCountryFilter(FILTER_NOT_SET);
    setPreorderFilter(FILTER_NOT_SET);
    setIsFilterActive(!isFilterActive);
  };

  useEffect(() => {
    setCountries(getCountryCodes);
    setSupervisors(getSupervisorNames);
  }, [data]);

  function getCountryCodes() {
    let countryCodes = new Set(
      data?.customer_registration.map(
        (registration) => registration.vendor?.country_code ?? VALUE_NOT_PRESENT
      )
    );
    countryCodes.delete(VALUE_NOT_PRESENT);

    return [...countryCodes].sort();
  }

  function getSupervisorNames() {
    let supervisorNames = new Set(
      data?.customer_registration.map(
        (registration) => registration.vendor?.supervisor ?? VALUE_NOT_PRESENT
      )
    );
    supervisorNames.delete(VALUE_NOT_PRESENT);
    return [...supervisorNames].sort();
  }

  useEffect(() => {
    const noFilterActive =
      vendorIdFilter === FILTER_NOT_SET &&
      supervisorFilter === FILTER_NOT_SET &&
      vendorNameFilter === FILTER_NOT_SET &&
      countryFilter === FILTER_NOT_SET &&
      preorderFilter === FILTER_NOT_SET;
    setIsFilterActive(!noFilterActive);
  }, [vendorIdFilter, supervisorFilter, vendorNameFilter, countryFilter, preorderFilter]);

  return (
    <Page title="Händler-Registrierung">
      <RegistrationEditDialog
        open={editModalOpen}
        onClose={closeEditModal}
        onSaveClicked={saveRegistration}
        registration={registrationUnderEdit!}
        updateRegistration={setRegistrationUnderEdit}
      />
      <DuplicateDialog
        ids={duplicateIds}
        open={duplicateModalOpen}
        onClose={() => {
          setDuplicateModalOpen(false);
        }}
      />
      <Dialog open={denyModalOpen} onClose={closeDenyModal} fullWidth maxWidth="sm">
        <DialogTitle>Ablehnen</DialogTitle>
        <DialogContent>
          <FormControl sx={{ my: 2 }} fullWidth>
            <InputLabel id="reason-select-label">Begründung</InputLabel>
            <Select
              value={denialReason.reason}
              label="Begründung"
              labelId="reason-select-label"
              onChange={onReasonChange}
            >
              <MenuItem value={DenialReason.duplicateRegistration}>Doppelte Anmeldung</MenuItem>
              <MenuItem value={DenialReason.noPreorder}>Keine Vororder</MenuItem>
              <MenuItem value={DenialReason.notAPartner}>Kein SQlab Partner</MenuItem>
              <MenuItem value={DenialReason.unsupportedCountry}>Land nicht unterstützt</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Kommentar (optional)"
            multiline
            rows={3}
            fullWidth
            value={denialReason.comment}
            onChange={onCommentChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDenyModal} color="secondary">
            Abbrechen
          </Button>
          <Button onClick={onDenyConfirmed} color="error">
            Ablehnen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={mismatchModalOpen}
        onClose={() => setMismatchModalOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Registrierung stimmt nicht mit BE-Daten überein</DialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          <Grid>
            <GridItem
              title={''}
              value1={<Typography variant="overline">Registrierung</Typography>}
              value2={<Typography variant="overline">BE-Daten</Typography>}
            />
            <GridItem
              title={'E-Mail'}
              value1={mismatchedRegistration?.email_address ?? ''}
              value2={mismatchedRegistration?.vendor?.email_address ?? ''}
            />
            <GridItem
              title={'Firmenname'}
              value1={mismatchedRegistration?.vendor_name ?? ''}
              value2={mismatchedRegistration?.vendor?.shop_name ?? ''}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setRegistrationToAccepted(mismatchedRegistration!)}
            color="success"
          >
            <CheckIcon />
            Trotzdem akzeptieren
          </Button>
          <Button variant="outlined" onClick={() => setMismatchModalOpen(false)} color="primary">
            Abbrechen
          </Button>
          <Button
            variant="outlined"
            color="info"
            onClick={() => {
              setMismatchModalOpen(false);
              editRegistration(mismatchedRegistration!);
            }}
          >
            <EditIcon />
            Bearbeiten
          </Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h3" component="h1" paragraph>
          Händler-Registrierung
        </Typography>
        <Box display="flex" gap={2} my={2}>
          <Button variant="outlined" onClick={triggerEmailPipeline}>
            <EmailIcon sx={{ mr: 2 }} />
            E-Mails verschicken
          </Button>
          <div style={{ flexGrow: 1 }} />
          <FormControlLabel
            control={<Switch defaultChecked onChange={handleShowOnlyNewChanged} />}
            label="Nur neue Anzeigen"
          />
          <Button variant="outlined" onClick={() => refetch()}>
            <RefreshIcon />
            Aktualisieren
          </Button>
        </Box>

        <Box display="flex" gap={2} my={2}>
          <Button disabled={!isFilterActive} onClick={resetFilters}>
            <FilterAltOffIcon
              sx={{
                width: 30,
                height: 30,
              }}
            />
            Filter zurücksetzen
          </Button>
          <TextField
            label="Kundennummer"
            rows={3}
            value={vendorIdFilter}
            onChange={(e) => setVendorIdFilter(e.target.value)}
          />
          <TextField
            label="Firmenname"
            rows={3}
            value={vendorNameFilter}
            onChange={(e) => setVendorNameFilter(e.target.value)}
          />
          {countries.length > 1 && (
            <FormControl>
              <InputLabel id="country-filter-label" sx={{ display: 'block' }}>
                Land
              </InputLabel>
              <Select
                sx={{ minWidth: '5rem' }}
                labelId="country-filter-label"
                value={countryFilter}
                onChange={(e) => setCountryFilter(e.target.value)}
              >
                {countries.map((country: string) => (
                  <MenuItem value={country} key={country}>
                    {country === FILTER_NOT_SET ? 'Not filtered' : country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {supervisors.length > 1 && (
            <FormControl>
              <InputLabel id="supervisor-filter-label">Kundenbetreuer</InputLabel>
              <Select
                sx={{ minWidth: '11rem' }}
                labelId="supervisor-filter-label"
                value={supervisorFilter}
                onChange={(e) => setSupervisorFilter(e.target.value)}
              >
                {supervisors.map((supervisor) => (
                  <MenuItem value={supervisor} key={supervisor}>
                    {supervisor}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl>
            <InputLabel id="preorder-filter-label">Vororder</InputLabel>
            <Select
              sx={{ minWidth: '7rem' }}
              labelId="preorder-filter-label"
              value={preorderFilter}
              onChange={(e) => setPreorderFilter(e.target.value)}
            >
              <MenuItem value={'true'} key={'preorder-true'}>
                Ja
              </MenuItem>
              <MenuItem value={'false'} key={'preorder-false'}>
                Nein
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        {loading && (
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {data?.customer_registration
          .filter((registration) => {
            const showPreorder = preorderFilter === 'true';
            return (
              (registration.vendor_id ?? '').startsWith(vendorIdFilter) &&
              (registration.vendor?.supervisor ?? '').startsWith(supervisorFilter) &&
              (registration.vendor_name ?? '').startsWith(vendorNameFilter) &&
              (registration.vendor?.country_code ?? '').startsWith(countryFilter) &&
              (preorderFilter === VALUE_NOT_PRESENT ||
                (!showPreorder && !isPreorder(registration)) ||
                (showPreorder && isPreorder(registration)))
            );
          })
          .map((registrationRequest) => (
            <RegistrationCard
              onEdit={editRegistration}
              onAccept={acceptRegistration}
              onDeny={showDenyRegistrationModal}
              showDuplicateDialog={showDuplicateDetails}
              key={registrationRequest.id}
              registration={registrationRequest}
            />
          )) ?? null}
      </Container>
    </Page>
  );
}
