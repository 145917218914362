import {
  AddTask,
  CameraAlt,
  Dashboard,
  FollowTheSigns,
  HelpCenter,
  HowToReg,
  ListAltOutlined,
  Mail,
  RocketLaunch,
  StarBorder,
  TableChart,
  Warning,
} from '@mui/icons-material';

const ICONS = {
  answers: <ListAltOutlined />,
  support: <HelpCenter />,
  vision: <CameraAlt />,
  boost: <RocketLaunch />,
  points_boost: <StarBorder />,
  dashboard: <Dashboard />,
  registration: <HowToReg />,
  email: <Mail />,
  tasks: <AddTask />,
  followUp: <FollowTheSigns />,
  vendor_support: <Warning />,
  product_matrix: <TableChart />,
};

const sidebarConfig = [
  {
    subheader: 'SQlab Admin',
    items: [
      {
        title: 'Dashboard',
        path: '/dashboard/metabase',
        icon: ICONS.dashboard,
      },
      {
        title: 'Support',
        path: '/dashboard/support',
        icon: ICONS.support,
      },
      {
        title: 'Tasks',
        path: '/dashboard/tasks',
        icon: ICONS.tasks,
      },
      {
        title: 'Händler-Registrierung',
        path: '/dashboard/registration',
        icon: ICONS.registration,
      },
      {
        title: 'Provisions Boost',
        path: '/dashboard/boost',
        icon: ICONS.boost,
      },
      {
        title: 'Punkte Boost',
        path: '/dashboard/points-boost',
        icon: ICONS.points_boost,
      },
      {
        title: 'Befragungen',
        path: '/dashboard/answers',
        icon: ICONS.answers,
      },
      // {title: 'Follow-up E-Mails', path: '/dashboard/follow-up-emails', icon: ICONS.email},
      {
        title: 'Follow-up Dashboard',
        path: '/dashboard/follow-up-dashboard',
        icon: ICONS.followUp,
      },
      // Händler Support
      {
        title: 'Händler Support',
        path: '/dashboard/vendor-support',
        icon: ICONS.vendor_support,
      },
      {
        title: 'Produkt-Matrix',
        path: '/dashboard/product-matrix',
        icon: ICONS.product_matrix,
      },
    ],
  },
  {
    subheader: 'SelectCode Admin',
    items: [
      {
        title: 'Computervision',
        path: '/dashboard/cv',
        icon: ICONS.vision,
      },
    ],
  },
];

export default sidebarConfig;
