import { useGetFollowUpTasksQuery } from '../../generated/graphql';
import { RegistrationsTable, RWATableCategory } from "./RegistrationsWithoutAnswersTable";

export function FollowUpTaskTable(props: {relevantSupervisors: string[]}) {
  const today = new Date()
  const todayString = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDay()

  const { data } = useGetFollowUpTasksQuery({ variables: { today: todayString } });
  const followUpTasks = data?.support_follow_up_tasks ?? [];

  let relevantFollowUpTasks
  if(props.relevantSupervisors.length === 0){
    relevantFollowUpTasks = followUpTasks
  } else {
    relevantFollowUpTasks = followUpTasks?.filter(registration => props.relevantSupervisors.indexOf(registration.vendor?.supervisor ?? '') > -1) ?? []
  }

  const headers = [
    RWATableCategory.VENDOR_ID,
    RWATableCategory.VENDOR_NAME,
    RWATableCategory.SUPERVISOR,
    RWATableCategory.FOLLOW_UP_AT,
    RWATableCategory.COMMENT,
  ];

  const nonSortableCategories = [
    RWATableCategory.COMMENT
  ];

  return (
        <RegistrationsTable
            headers={headers}
            nonSortableHeaders={nonSortableCategories}
            registrations={relevantFollowUpTasks}
            setDiscardTask={null}
            defaultSorting={RWATableCategory.FOLLOW_UP_AT}
        />
        );
}