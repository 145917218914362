import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useGetRegistrationsByIdQuery } from '../../generated/graphql';
import { RegistrationCard } from './RegistrationCard';

export function DuplicateDialog({
  ids,
  open,
  onClose,
}: {
  ids: number[];
  open: boolean;
  onClose: () => void;
}) {
  const { data } = useGetRegistrationsByIdQuery({ variables: { ids } });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>Anmeldungen mit der selben Kundennummer</DialogTitle>
      <DialogContent>
        <Box my={2} gap={2}>
          {data === undefined
            ? null
            : data.customer_registration.map((registration) => (
                <RegistrationCard
                  key={registration.id}
                  registration={registration}
                  showActions={false}
                  onEdit={() => {}}
                  onAccept={() => {}}
                  onDeny={() => {}}
                  showDuplicateDialog={() => {}}
                />
              ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
