import React, { FC } from 'react'
import { LocalizationProviderProps } from '@mui/lab/LocalizationProvider'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export type DateFnsProviderProps = FC<Omit<LocalizationProviderProps, 'dateAdapter'> & {
    utils?: any
}>

const DateFnsProvider: DateFnsProviderProps = ({
                                                   children,
                                                   utils,
                                                   ...props
                                               }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs} {...props}>
            {children}
        </LocalizationProvider>
    )
export default DateFnsProvider
