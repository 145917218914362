import { RegistrationRequestFragment } from '../../generated/graphql';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { VendorSize } from '../../pages/PageProfilerRegistration';

export function RegistrationEditDialog(props: {
  open: boolean;
  onClose: () => void;
  onSaveClicked: () => void;
  updateRegistration: (r: RegistrationRequestFragment) => void;
  registration: RegistrationRequestFragment;
}) {
  function updateAttribute(attributeName: keyof RegistrationRequestFragment, value: any) {
    props.updateRegistration({ ...props.registration, [attributeName]: value });
  }

  if (props.registration == null) {
    return <div />;
  }
  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="lg">
      <DialogTitle>Registrierung Bearbeiten</DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={2} alignItems={'center'} pt={2} mb={2}>
          <TextField
            autoFocus
            margin="dense"
            id="first_name"
            label="Vorname"
            type="text"
            fullWidth
            value={props.registration?.first_name}
            onChange={(e) => updateAttribute('first_name', e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="first_name"
            label="Nachname"
            type="text"
            fullWidth
            onChange={(e) => updateAttribute('surname', e.target.value)}
            value={props.registration?.surname}
          />
        </Stack>
        <TextField
          sx={{ mb: 2 }}
          autoFocus
          margin="dense"
          id="name"
          label="E-Mail-Adresse"
          type="email"
          fullWidth
          onChange={(e) => updateAttribute('email_address', e.target.value)}
          value={props.registration?.email_address}
        />
        <TextField
          sx={{ mb: 2 }}
          autoFocus
          margin="dense"
          id="name"
          label="Firmenname"
          type="text"
          fullWidth
          onChange={(e) => updateAttribute('vendor_name', e.target.value)}
          value={props.registration?.vendor_name}
        />
        <Stack direction="row" spacing={2} alignItems="center" mb={2}>
          <TextField
            autoFocus
            margin="dense"
            id="vendor_id"
            label="Kundennummer"
            type="email"
            onChange={(e) => updateAttribute('vendor_id', e.target.value)}
            value={props.registration?.vendor_id}
          />
          <FormControl sx={{ my: 2 }}>
            <InputLabel id="language">Sprache</InputLabel>
            <Select
              labelId="language"
              value={props.registration?.language}
              label="Sprache"
              onChange={(e) => updateAttribute('language', e.target.value)}
            >
              <MenuItem value={'DE'}>DE</MenuItem>
              <MenuItem value={'EN'}>EN</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" gap={2} mb={2}>
          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel id="ipad-available-label">iPad vorhanden</InputLabel>
            <Select
              onChange={(e) =>
                updateAttribute(
                  'ipad_available',
                  e.target.value === 'null' ? null : e.target.value === 'true'
                )
              }
              labelId="ipad-available-label"
              value={String(props.registration?.ipad_available)}
              label="iPad vorhanden"
            >
              <MenuItem value={'null'}>Unbekannt</MenuItem>
              <MenuItem value={'true'}>Ja</MenuItem>
              <MenuItem value={'false'}>Nein</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ my: 2 }}>
            <InputLabel id="vendor-size-label">Mitarbeiter</InputLabel>
            <Select
              labelId="vendor-size-label"
              value={props.registration.vendor_size}
              label="Mitarbeiter"
              onChange={(e) => updateAttribute('vendor_size', e.target.value)}
            >
              <MenuItem value={VendorSize.fewerThanFive}>{'<'} 5</MenuItem>
              <MenuItem value={VendorSize.betweenFiveAndFifteen}>{'5 - 15'}</MenuItem>
              <MenuItem value={VendorSize.moreThanFifteen}>{'> 15'}</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <TextField
          sx={{ mb: 2 }}
          value={props.registration.additional_email_addresses}
          multiline
          rows={3}
          label="Zusätzliche E-Mail-Adressen"
          fullWidth
          onChange={(e) => updateAttribute('additional_email_addresses', e.target.value)}
        />
        <TextField
          value={props.registration.comment}
          label="Kommentar"
          fullWidth
          onChange={(e) => updateAttribute('comment', e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="error">
          Abbrechen
        </Button>
        <Button onClick={props.onSaveClicked} color="secondary">
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
}
