import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Page from '../components/Page';
import {
  useGetRegistrationIdsWithPlannedFollowUpQuery,
  useGetRegistrationsForFollowUpEmailsQuery,
} from '../generated/graphql';
import { useState } from 'react';
import { RegistrationStatus } from './PageProfilerRegistration';
import {
  DataItem,
  formatDate,
  CustomGrid,
  RegistrationStatusWidget,
  GridItem,
} from '../components/registration/RegistrationCard';
import EmailIcon from '@mui/icons-material/Email';
import PlanFollowUpEmailDialog, {
  formatDateStringWithoutClock,
} from '../components/FollowUpEmails/PlanFollowUpEmailDialog';

export default function PageFollowUpEmails() {
  const FILTER_NOT_SET = '';
  const [vendorIdFilter, setVendorIdFilter] = useState(FILTER_NOT_SET);

  const [showPlanEmailDialog, setShowPlanEmailDialog] = useState(false);
  const [selectedRegistration, setSelectedRegistration] = useState(undefined);

  const { data, loading } = useGetRegistrationsForFollowUpEmailsQuery({
    variables: {
      status: [RegistrationStatus.accessGranted, RegistrationStatus.accessGrantedSent],
    },
  });

  const { data: registrationIdsWithPlannedFollowUp, refetch } =
    useGetRegistrationIdsWithPlannedFollowUpQuery();

  function isEmailAlreadyPlanned(id: number): { isFollowUpPlanned: boolean; scheduledAt?: any } {
    const plannedEmailsWithId = registrationIdsWithPlannedFollowUp?.onboarding_emails.filter(
      (onboardingEmail) => onboardingEmail.registration_id === id
    );

    const isEmailPlanned: boolean = plannedEmailsWithId ? plannedEmailsWithId.length > 0 : false;
    return {
      isFollowUpPlanned: isEmailPlanned,
      scheduledAt:
        isEmailPlanned && plannedEmailsWithId ? plannedEmailsWithId[0].scheduled_at : undefined,
    };
  }

  async function handleNewFollowUpPlanned() {
    setShowPlanEmailDialog(false);
    await refetch();
  }

  return (
    <Page title="Follow-Up E-Mails">
      <PlanFollowUpEmailDialog
        open={showPlanEmailDialog}
        onSaveClicked={() => handleNewFollowUpPlanned()}
        onClose={() => setShowPlanEmailDialog(false)}
        registration={selectedRegistration}
      />
      <Typography variant="h3" component="h1" paragraph>
        Follow-Up E-Mails
      </Typography>
      <TextField
        label="Kundennummer"
        rows={3}
        value={vendorIdFilter}
        onChange={(e) => setVendorIdFilter(e.target.value)}
        sx={{ mb: 3 }}
      />
      {loading && (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {data &&
        data.customer_registration
          .filter((registration) => registration.vendor_id?.startsWith(vendorIdFilter))
          .map((registration) => {
            const { isFollowUpPlanned, scheduledAt } = isEmailAlreadyPlanned(registration.id);
            return (
              <Card key={`follow-up-email-registration-${registration.id}`} sx={{ mb: 2 }}>
                <CardContent>
                  <Stack direction="row" gap={4} sx={{ overflow: 'auto' }}>
                    <CustomGrid>
                      <GridItem>
                        <Typography variant={'h6'}>Registrierungs ID: {registration.id}</Typography>
                        <RegistrationStatusWidget
                          status={registration.status as RegistrationStatus}
                        />
                      </GridItem>
                      <DataItem label={'Firmenname'}>{registration.vendor_name}</DataItem>
                      <DataItem label={'Kundennummer'}> {registration.vendor_id}</DataItem>
                      <DataItem label={'E-Mail'}>{registration.email_address}</DataItem>
                      <DataItem label={'Registrierungsdatum'}>
                        {formatDate(registration.created_at, true)}
                      </DataItem>
                      <DataItem label={'E-Mail versendet'}>
                        {formatDate(registration.registration_email_sent_at, true)}
                      </DataItem>
                    </CustomGrid>
                  </Stack>
                  <Button
                    variant="outlined"
                    sx={{ mt: 2 }}
                    onClick={() => {
                      setShowPlanEmailDialog(true);
                      // @ts-ignore
                      setSelectedRegistration(registration);
                    }}
                    disabled={isFollowUpPlanned}
                  >
                    <EmailIcon sx={{ mr: 2 }} />
                    E-Mail planen
                  </Button>
                  {isFollowUpPlanned && (
                    <Typography variant={'subtitle2'} mt={1}>
                      Es wurde bereits eine Follow-Up Email für den{' '}
                      {formatDateStringWithoutClock(scheduledAt)} geplant!
                    </Typography>
                  )}
                </CardContent>
              </Card>
            );
          })}
    </Page>
  );
}
