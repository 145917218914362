// @mui
import {
  Card, Checkbox,
  Container,
  FormControl,
  InputLabel, ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Typography
} from '@mui/material';
// hooks
import useSettings from '../hooks/useSettings';
// components
import Page from '../components/Page';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useState } from 'react';
import { FollowTheSigns, PendingOutlined, PlayCircleOutline, StopCircleOutlined } from '@mui/icons-material';
import { RegistrationsWithoutAnswersTable } from '../components/tasks/RegistrationsWithoutAnswersTable';
import { RegistrationsWithoutProgressTable } from '../components/tasks/RegistrationsWithoutProgressTable';
import { ExpiringCommissionBoostsTable } from '../components/tasks/ExpiringCommissionBoostsTable';
import { useGetSalesPeopleQuery } from "../generated/graphql";
import { FollowUpTaskTable } from "../components/tasks/FollowUpTaskTable";

// ----------------------------------------------------------------------

export function helloWorld() {
  alert('Anruf eingeplant!');
}

export default function PageTasks() {
  const { themeStretch } = useSettings();

  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  const [supervisors, setSupervisors] = useState<string[]>([]);

  const changeSelectionOfSupervisor = (event: SelectChangeEvent<typeof supervisors>) => {
    const {
      target: { value },
    } = event;
    setSupervisors(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(';') : value,
    );
  };

  const salesPeople = useGetSalesPeopleQuery().data?.sqlab_contacts.map((salesPerson =>
    salesPerson.name
  ))

  const SIMPLE_TAB = [
    {
      value: '1',
      icon: <PlayCircleOutline />,
      label: '1 Woche ohne Befragungen',
      component: <RegistrationsWithoutAnswersTable relevantSupervisors={supervisors} />,
    },
    {
      value: '2',
      icon: <PendingOutlined />,
      label: '2 Wochen mit < 5 Befragungen',
      component: <RegistrationsWithoutProgressTable relevantSupervisors={supervisors}/>,
    },
    // {
    //   value: '3',
    //   icon: <Iconify icon='mdi:hand-back-right-outline' width={24} height={24} />,
    //   label: 'Kämpfende Händler',
    //   component: <StrugglingVendorsTable />,
    // },
    {
      value: '4',
      icon: <StopCircleOutlined />,
      label: 'Auslaufende Provisions-Boosts',
      component: <ExpiringCommissionBoostsTable relevantSupervisors={supervisors}/>,
    },
    {
      value: '5',
      icon: <FollowTheSigns />,
      label: 'Follow Up Tasks',
      component: <FollowUpTaskTable relevantSupervisors={supervisors}/>,
    },
  ];



  return (
    <Page title='Anstehende Telefonate'>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <div style={{ display:'flex', marginBottom: '1em' }}>
        <Typography variant='h3' component='h1' paragraph style={{ marginRight: 'auto' }}>
          Anstehende Telefonate
        </Typography>
          <Typography variant={'h6'} mr={1} style={{ alignSelf: "center" }}>
            Filteroptionen:
          </Typography>
          <FormControl style={{ minWidth: '30%', maxWidth: '30%' }} >
            <InputLabel id="supervisor-selection-label">
              Kundenbetreuer
            </InputLabel>
            <Select
                labelId="supervisor-selection-label"
                id="supervisor-selection"
                multiple
                value={supervisors}
                label="Kundenbetreuer"
                onChange={changeSelectionOfSupervisor}
                renderValue={(selected) => selected.join('; ')}

            >
              {salesPeople?.map(
                  salesPerson =>
                    <MenuItem value={salesPerson} key={salesPerson}>
                      <Checkbox checked={supervisors.indexOf(salesPerson) > -1}/>
                      <ListItemText primary={salesPerson}/>
                    </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>

        <Card>
          <TabContext value={value}>

            <TabList onChange={handleChange} centered>
              {SIMPLE_TAB.map((tab, index) => (
                <Tab key={tab.value} label={tab.label} value={String(index + 1)} icon={tab.icon} />
              ))}
            </TabList>

            {SIMPLE_TAB.map((panel, index) => (
              <TabPanel key={panel.value} value={String(index + 1)}>
                {panel.component}
              </TabPanel>
            ))}
          </TabContext>
        </Card>
      </Container>
    </Page>
  );
}