import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { RegistrationsFragment, useInsertOnboardingEmailMutation } from '../../generated/graphql';
import { DatePickerElement, FormContainer } from 'react-hook-form-mui';
import { toast } from 'react-toastify';
import { formatDate } from '../registration/RegistrationCard';

type OnboardingEmail = 'FOLLOW_UP';

export function formatDateStringWithoutClock(dateString: string) {
  const formattedDate = formatDate(dateString);
  return formattedDate.split(',')[0];
}

export default function PlanFollowUpEmailDialog(props: {
  open: boolean;
  onSaveClicked: () => void;
  onClose: () => void;
  scheduledAt?: Date;
  registration?: RegistrationsFragment;
}) {
  const [insertRegistration] = useInsertOnboardingEmailMutation();

  const today = new Date().toISOString();

  function closeModal() {
    props.onSaveClicked();
  }

  async function insertFollowUpEmailToHasura(scheduledAtUnparsed: string) {
    const { registration } = props;
    const isRegistrationAndScheduleAtDefined = registration && scheduledAtUnparsed;
    const toastErrorMessage = 'Die Follow-Up Email konnte nicht erstellt werden ';
    if (!isRegistrationAndScheduleAtDefined) {
      console.error('registration or scheduledAt not defined', {
        registration: registration,
        scheduledAt: scheduledAtUnparsed,
      });
      toast.error(toastErrorMessage);
      return;
    }

    const currentTime = new Date().toUTCString();

    const scheduledAtDate: Date = new Date(scheduledAtUnparsed);
    let scheduledAtString: string = scheduledAtDate.toISOString();
    const onboardingEmailType: OnboardingEmail = 'FOLLOW_UP';

    try {
      await insertRegistration({
        variables: {
          created_at: currentTime,
          language: registration.language,
          registration_id: registration.id,
          scheduled_at: scheduledAtString,
          type: onboardingEmailType,
        },
      });

      const dateWithoutHours = formatDateStringWithoutClock(scheduledAtString);
      toast.success(
        `Die Follow-Up EMail für ${registration.vendor_name} wird am ${dateWithoutHours} an ${registration.email_address} verschickt`
      );
    } catch (error) {
      const ERROR_DUPLICATE_KEY =
        'Uniqueness violation. duplicate key value violates unique constraint "emails_pkey"';
      if (error.message === ERROR_DUPLICATE_KEY) {
        toast.error('Es wurde bereits eine Erinnerung für die Registrierung erstellt!');
        return;
      }

      toast.error(toastErrorMessage + error.toString());
    }

    closeModal();
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Follow-Up Email für <i>{props.registration?.vendor_name}</i> planen!
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <FormContainer
          onSuccess={(data) => {
            insertFollowUpEmailToHasura(data.start_date);
          }}
        >
          <Container>
            <Typography mb={2}>
              Eine Follow-Up Email, in welcher der Händler nach Feedback gefragt wird und an den
              Kundenbetreuer erinnert wird, wird an folgendem Datum an{' '}
              <i>{props.registration?.email_address}</i> verschickt:
            </Typography>
            <DatePickerElement
              inputProps={{ size: 'small' }}
              name="start_date"
              required
              validation={{ min: today }}
            />

            <DialogActions>
              <Button onClick={props.onClose} color="error">
                Abbrechen
              </Button>
              <Button color="secondary" type={'submit'}>
                Speichern
              </Button>
            </DialogActions>
          </Container>
        </FormContainer>
      </DialogContent>
    </Dialog>
  );
}
