import { useRegistrationsWithoutAnswersQuery, useStoreInteractionMutation } from '../../generated/graphql';
import {
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TableSortLabel,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useState } from "react";
import { Order } from "../../pages/retail/CustomerAnswerList";
import Label from "../Label";

export enum RWATableCategory {
  ACCEPTANCE_DATE,
  VENDOR_ID,
  VENDOR_NAME,
  SUPERVISOR,
  LANGUAGE,
  IPAD_AVAILABLE,
  DENY,
  ANSWER_COUNT,
  END_DATE,
  FACTOR,
  COMMENT,
  FOLLOW_UP_AT
}

export function RegistrationsTable({ headers, nonSortableHeaders, registrations, setDiscardTask, defaultSorting } : {headers: RWATableCategory[], nonSortableHeaders: RWATableCategory[], registrations: any, setDiscardTask: any, defaultSorting: RWATableCategory}) {
  const navigate = useNavigate();

  const [sortBy, setSortBy] = React.useState(defaultSorting == null ? RWATableCategory.ACCEPTANCE_DATE : defaultSorting);
  // if false then sorting is Ascending
  const [sortOrder, setSortOrder] = React.useState('desc' as Order);

  //const [discardTask, setDiscardTask] = useState<any | null>(null)

  const toggleSorting = () => {
    if (sortOrder === "desc")
      setSortOrder("asc")
    else setSortOrder("desc")
  }

  function handleSortingClicked(category: RWATableCategory) {
    if(category === RWATableCategory.DENY) return

    const newCategory = category
    if (newCategory === sortBy) {
      toggleSorting()
    } else {
      setSortBy(newCategory)
    }
  }

  function getHeaderColor(header: any) {
    return (header === sortBy ? "primary" : "secondary")
  }

  function sortRegistrations(a: any, b: any) {
    switch (sortBy) {
      case RWATableCategory.ACCEPTANCE_DATE: {
        const aTime = new Date(a.registration_email_sent_at)
        const bTime = new Date(b.registration_email_sent_at)
        if (sortOrder === "desc") {
          return bTime.getTime() - aTime.getTime()
        } else {
          return aTime.getTime() - bTime.getTime()
        }
      }
      case RWATableCategory.VENDOR_ID:
      {
        let aVendorName = a.vendor_id as number
        let bVendorName = b.vendor_id as number

        let result = 0
        if(aVendorName > bVendorName) result = -1; // is descending order
        else result = 1

        if(sortOrder === "asc") {
          result = -result
        }
        return result
      }

      case RWATableCategory.VENDOR_NAME: {
        if (a.vendor == null || b.vendor == null) {
          return 0;
        } else if (a.vendor!.shop_name == null || b.vendor!.shop_name == null) {
          return 0
        }


        const aVendorName = a.vendor!.shop_name
        const bVendorName = b.vendor!.shop_name
        if (aVendorName == null || bVendorName == null) return 0

        let returnValue = 0
        if (aVendorName > bVendorName) returnValue = -1
        else returnValue = 1
        if (aVendorName === "" && bVendorName === "") returnValue = 0
        else {
          if (aVendorName === "") returnValue = 1
          if (bVendorName === "") returnValue = -1
        }
        if (sortOrder === "asc") {
          returnValue = -returnValue
        }
        return returnValue
      }

      case RWATableCategory.SUPERVISOR: {
        if (a.vendor == null || b.vendor == null) {
          return 0;
        } else if (a.vendor!.supervisor == null || b.vendor!.supervisor == null) {
          return 0
        }


        const aVendorName = a.vendor!.supervisor
        const bVendorName = b.vendor!.supervisor
        if (aVendorName == null || bVendorName == null) return 0

        let returnValue = 0
        if (aVendorName > bVendorName) returnValue = -1
        else returnValue = 1
        if (aVendorName === "" && bVendorName === "") returnValue = 0
        else {
          if (aVendorName === "") returnValue = 1
          if (bVendorName === "") returnValue = -1
        }
        if (sortOrder === "asc") {
          returnValue = -returnValue
        }
        return returnValue
      }

      case RWATableCategory.LANGUAGE: {
        let aLanguage = a.language as string
        let bLanguage = b.language as string

        let result = 0
        if(aLanguage > bLanguage) result = -1 // descening order
        else result = 1

        if(sortOrder === "asc") {
          result = -result
        }
        return result
      }

      case RWATableCategory.ANSWER_COUNT: {
        let aVendorName = a.answer_count as number
        let bVendorName = b.answer_count as number

        let result = 0
        if(aVendorName > bVendorName) result = -1; // is descending order
        else result = 1

        if(sortOrder === "asc") {
          result = -result
        }
        return result
      }

      case RWATableCategory.END_DATE: {
        const aTime = new Date(a.end_date)
        const bTime = new Date(b.end_date)
        if (sortOrder === "desc") {
          return bTime.getTime() - aTime.getTime()
        } else {
          return aTime.getTime() - bTime.getTime()
        }
      }

      case RWATableCategory.FACTOR: {
        const aFactor = a.factor
        const bFactor = b.factor

        let result = bFactor - aFactor
        if(sortOrder === "asc") result = -result
        return result
      }

      case RWATableCategory.FOLLOW_UP_AT: {
        const aTime = new Date(a.follow_up_at)
        const bTime = new Date(b.follow_up_at)
        if (sortOrder === "asc") {
          return bTime.getTime() - aTime.getTime()
        } else {
          return aTime.getTime() - bTime.getTime()
        }
      }
    }

    return -1;
  }

  function tableColumnSortingDisabled(column: RWATableCategory) {
    return nonSortableHeaders.includes(column)
  }

  function getColumnString(column: any) {
    switch (column) {
      case RWATableCategory.ACCEPTANCE_DATE:
        return "Freigabe-Datum"
      case RWATableCategory.VENDOR_ID:
        return "Händlernummer"
      case RWATableCategory.VENDOR_NAME:
        return "Händlername"
      case RWATableCategory.SUPERVISOR:
        return "Kundenbetreuer"
      case RWATableCategory.LANGUAGE:
        return "Sprache"
      case RWATableCategory.IPAD_AVAILABLE:
        return "iPad vorhanden?"
      case RWATableCategory.DENY:
        return "Ablehnen"
      case RWATableCategory.ANSWER_COUNT:
        return "Anzahl Befragungen"
      case RWATableCategory.END_DATE:
        return "End-Datum"
      case RWATableCategory.FACTOR:
        return "Faktor"
      case RWATableCategory.COMMENT:
        return "Kommentar"
      case RWATableCategory.FOLLOW_UP_AT:
        return "Geplante Interaktion am:"
    }
  }

  return <TableContainer sx={{ minWidth: 800 }}>
      <Table>
        <TableHead>
          <TableRow>
            {
              headers.map(it =>
                  <TableCell key={it}>
                    <TableSortLabel
                        active={it === sortBy}
                        disabled={tableColumnSortingDisabled(it)}
                        direction={sortOrder}
                        onClick={() => {
                          handleSortingClicked(it)
                        }}
                    >
                      <Button disabled={tableColumnSortingDisabled(it)} color={getHeaderColor(it)} onClick={() => {
                        handleSortingClicked(it)
                      }}>{getColumnString(it)}</Button>
                    </TableSortLabel>
                  </TableCell>)
            }
          </TableRow>
        </TableHead>
        {registrations.length === 0
            ? <p style={{ textAlign: "center" }}> Keine Tasks vorhanden </p>
            : <TableBody>
              {registrations
                  .sort((a: any,b: any) => sortRegistrations(a,b))
                  .map((it: any) => {
                    let cellList: any[] = []

                    headers.forEach( function (header) {
                      if(header === (RWATableCategory.ACCEPTANCE_DATE)) {
                        const date = new Date(Date.parse(it.registration_email_sent_at));
                        const time_txt = date.toLocaleString('de-DE', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        });
                        cellList.push(<TableCell align='left'>{time_txt}</TableCell>)
                      } else if(header === (RWATableCategory.VENDOR_ID)){
                        cellList.push(<TableCell align='left'>{it.vendor_id}</TableCell>)
                      }
                      else if(header === (RWATableCategory.VENDOR_NAME)){
                        cellList.push(<TableCell align='left'>{it.vendor?.shop_name}</TableCell>)
                      } else if(header === RWATableCategory.SUPERVISOR) {
                        cellList.push(<TableCell align='left'>{it.vendor?.supervisor}</TableCell>)
                      } else if(header === RWATableCategory.LANGUAGE) {
                        cellList.push(<TableCell align='left'>{it.language}</TableCell>)
                      } else if(header === RWATableCategory.IPAD_AVAILABLE) {
                        cellList.push(<TableCell align='left'>{it.ipad_available}</TableCell>)
                      } else if(header === RWATableCategory.DENY) {
                        cellList.push(<TableCell align='left'><CancelIcon cursor={'pointer'} onClick={(e) => {
                          setDiscardTask(it)
                          e.stopPropagation()
                        }}/></TableCell>)
                      } else if(header === RWATableCategory.ANSWER_COUNT) {
                        cellList.push(<TableCell align='left'>
                          <Label color={it.answer_count === 0 ? 'error' : 'warning'}>{it.answer_count}</Label>
                        </TableCell>)
                      } else if(header === RWATableCategory.END_DATE) {
                        const endDate = new Date(it.end_date);
                        const endDateString = endDate.toLocaleString('de-DE', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        });
                        cellList.push(<TableCell align='left'>{endDateString}</TableCell>)
                      } else if(header === RWATableCategory.FACTOR) {
                        cellList.push(<TableCell align='left'>{it.factor}</TableCell>)
                      } else if(header === RWATableCategory.COMMENT) {
                        cellList.push(<TableCell align='left'>{it.comment}</TableCell>)
                      } else if(header === RWATableCategory.FOLLOW_UP_AT) {
                        const followUpDate = new Date(it.follow_up_at);
                        const followUpDateString = followUpDate.toLocaleString('de-DE', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        });
                        cellList.push(<TableCell align='left'>{followUpDateString}</TableCell>)
                      }

                    })

                    return <TableRow
                        hover
                        key={it.id}
                        tabIndex={-1}
                        sx={{
                          // backgroundColor: error_id === 0 ? "transparent" : palette.warning.light
                          backgroundColor: 'transparent',
                        }}
                        role='checkbox'
                        onClick={() => {
                          navigate('/dashboard/support?vendorId=' + it.vendor_id);
                        }
                        }
                    >
                      {cellList}
                    </TableRow>;
                  })
              }
            </TableBody>
        }
      </Table>
    </TableContainer>;
}

export function RegistrationsWithoutAnswersTable(props: {relevantSupervisors: string[]}) {

  const { data, refetch } = useRegistrationsWithoutAnswersQuery();
  let allRegistrations = data?.support_registrations_without_answers ?? [];

  let relevantRegistrations
  if(props.relevantSupervisors .length === 0){
    relevantRegistrations = allRegistrations
  } else {
    relevantRegistrations = allRegistrations?.filter(registration => props.relevantSupervisors.indexOf(registration.vendor?.supervisor ?? '') > -1) ?? []
  }

  const registrations = relevantRegistrations.filter((registration: any) =>
    (!registration.vendor?.metadata?.includes('noProf')) && registration.vendor?.country_code === 'DE'
  )


  const headers = [
    RWATableCategory.ACCEPTANCE_DATE,
    RWATableCategory.VENDOR_ID,
    RWATableCategory.VENDOR_NAME,
    RWATableCategory.SUPERVISOR,
    RWATableCategory.LANGUAGE,
    RWATableCategory.IPAD_AVAILABLE,
    RWATableCategory.DENY
  ];

  const nonSortableHeaders = [
    RWATableCategory.DENY,
    RWATableCategory.IPAD_AVAILABLE,
    RWATableCategory.COMMENT
  ]


  const [discardTask, setDiscardTask] = useState<any | null>(null)

  const [storeInteraction] = useStoreInteractionMutation();
  async function handleDiscardTask(){
    await storeInteraction({
      variables: {
        comment: '',
        contact: '',
        contactedAt: new Date(),
        type: "DISCARDED_TASK",
        teamMemberName: '',
        vendorId: discardTask?.vendor_id,
      },
    });
    setDiscardTask(null)
    refetch()
  }


  return (<>
        <RegistrationsTable headers={headers} nonSortableHeaders={nonSortableHeaders} registrations={registrations} setDiscardTask={setDiscardTask} defaultSorting={RWATableCategory.ACCEPTANCE_DATE}/>
  <Dialog open={Boolean(discardTask)}>
    <div style={{ padding: '1em', display: "flex", flexDirection: "column" }}>
      <Typography variant={'h4'} style={{ marginBottom: '1em' }}>Nutzer deaktivieren</Typography>
      <p style={{ marginBottom: '1em' }}>Möchten Sie das anstehende Telefonat mit <b>{discardTask?.vendor?.shop_name}</b> wirklich ablehnen?</p>
      <div style={{ marginLeft: 'auto' }}>
        <Button onClick={() => setDiscardTask(null)} variant={'outlined'}>Abbrechen</Button>
        <Button onClick={() => handleDiscardTask()} variant={'contained'} style={{ marginLeft: '1em' }}>Ja</Button>
      </div>
    </div>
  </Dialog>
</>
  );
}