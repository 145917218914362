import { Button, Dialog, Typography } from '@mui/material';
import { useRegistrationsWithoutProgressQuery, useStoreInteractionMutation } from '../../generated/graphql';
import { useState } from "react";
import { RegistrationsTable, RWATableCategory } from "./RegistrationsWithoutAnswersTable";

export function RegistrationsWithoutProgressTable(props:{relevantSupervisors: string[]}) {

  const { data, refetch } = useRegistrationsWithoutProgressQuery();
  const allRegistrations = data?.support_registrations_without_progress_two_weeks ?? [];

  let relevantRegistrations
  if(props.relevantSupervisors .length === 0){
    relevantRegistrations = allRegistrations
  } else {
    relevantRegistrations = allRegistrations?.filter(registration => props.relevantSupervisors.indexOf(registration.vendor?.supervisor ?? '') > -1) ?? []
  }

  const registrations = relevantRegistrations.filter(registration =>
      (!registration.vendor?.metadata?.includes('noProf')) && registration.vendor?.country_code === 'DE'
  )

  const headers = [
    RWATableCategory.ACCEPTANCE_DATE,
    RWATableCategory.VENDOR_ID,
    RWATableCategory.VENDOR_NAME,
    RWATableCategory.ANSWER_COUNT,
    RWATableCategory.SUPERVISOR,
    RWATableCategory.LANGUAGE,
    RWATableCategory.IPAD_AVAILABLE,
    RWATableCategory.DENY
  ];

  const nonSortableHeaders = [
    RWATableCategory.IPAD_AVAILABLE,
    RWATableCategory.DENY
  ]

  const [discardTask, setDiscardTask] = useState<any | null>(null)

  const [storeInteraction] = useStoreInteractionMutation();
  async function handleDiscardTask(){
    await storeInteraction({
      variables: {
        comment: '',
        contact: '',
        contactedAt: new Date(),
        type: "DISCARDED_TASK",
        teamMemberName: '',
        vendorId: discardTask?.vendor_id,
      },
    });
    setDiscardTask(null)
    refetch()
  }

  return (<>
    <RegistrationsTable headers={headers} nonSortableHeaders={nonSortableHeaders} registrations={registrations} setDiscardTask={setDiscardTask} defaultSorting={RWATableCategory.ACCEPTANCE_DATE}/>
    <Dialog open={Boolean(discardTask)}>
      <div style={{ padding: '1em', display: "flex", flexDirection: "column" }}>
        <Typography variant={'h4'} style={{ marginBottom: '1em' }}>Nutzer deaktivieren</Typography>
        <p style={{ marginBottom: '1em' }}>Möchten Sie das anstehende Telefonat mit <b>{discardTask?.vendor?.shop_name}</b> wirklich ablehnen?</p>
        <div style={{ marginLeft: 'auto' }}>
          <Button onClick={() => setDiscardTask(null)} variant={'outlined'}>Abbrechen</Button>
          <Button onClick={() => handleDiscardTask()} variant={'contained'} style={{ marginLeft: '1em' }}>Ja</Button>
        </div>
      </div>
    </Dialog>
    </>
  )
    ;
}